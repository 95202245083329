<template>
  <div class="couponList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>推送列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>版本号:</span>
      <el-input v-model.trim="list.title" placeholder="请输入标题"></el-input>
      <span>范围:</span>
      <el-select placeholder="请选择范围" v-model="list.type">
        <el-option
          v-for="item in versionType"
          :key="item.value"
          :label="item.way"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="tableList()">查找</el-button>
      <el-button type="primary" @click="$router.push('/pushMessage')"
        >添加</el-button
      >
      <!-- <el-button type="primary" @click="$router.push(`/addCoupon/0`)"
      v-if=" $store.state.powerList.indexOf('coupon:info:list:add') !== -1"
        >添加</el-button
      > -->
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="formData"
      border
      style="width: 100%"
    >
      <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="pushId"
        label="id"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="title"
        label="标题"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="content"
        label="内容"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="pushType"
        label="范围"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="推送时间"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="状态"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="goEdit(row.pushId)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onChangeStatus(row.pushId, 2)"
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onPushMessage(row.pushId, row.status)"
            >发送</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { pushList, delPush, pushOut } from "../../api/appManage";
export default {
  name: "couponList",
  data() {
    return {
      versionType: [
        { value: 0, way: "全部" },
        { value: 1, way: "IOS" },
        { value: 2, way: "Android" },
      ],
      options2: [
        { value: 0, way: "全部" },
        { value: 1, way: "财务" },
        { value: 2, way: "运营" },
      ],
      fit: "fill",
      formData: [],
      pagination: {},
      ids: "",
      options: [
        {
          value: 1,
          label: "手动领取",
        },
        {
          value: 2,
          label: "注册领取",
        },
      ],
      options1: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 0,
          label: "未使用",
        },
        {
          value: 1,
          label: "已使用",
        },
        {
          value: 2,
          label: "已过期",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        title: "",
        type: "",
      },
      dialogVisible: false,
      QRCode: "",
    };
  },
  created() {
    this.tableList();
  },
  methods: {
    onPushMessage(id, status) {
      if (status == "已发送") {
        console.log("是的");
        this.$message({
          message: "消息已发送，不能重复推送(⊙﹏⊙)",
          type: "error",
        });
        return;
      }
      this.$confirm(`是否要发送？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const { data } = await pushOut({
          pushId: id,
        });
        if (data.code == 0) {
          this.$message({
            type: "success",
            message: data.msg,
          });
          this.tableList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
          });
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    goEdit(id) {
      console.log(id, "id");
      this.$router.push(`/pushMessage?id=${id}`);
    },
    async goCouponQRCode(couponId) {
      this.dialogVisible = true;
      const { data } = await couponQRCode({
        couponId: couponId,
      });
      if (data.code == 0) {
        console.log(data.msg);
        this.QRCode = data.msg;
        console.log("图片", this.QRCode);
      }
    },
    async tableList() {
      const { data } = await pushList(this.list);
      this.formData = data.list;
      this.pagination = data.pagination;
    },
    onChangeStatus(id) {
      this.$confirm(`是否要删除`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delPush({
            ids: id,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onDelRow(id) {
      this.$confirm("是否要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: id + "",
            status: 0,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.ids = arr.join(",");
    },
    async onChangeAll(status) {
      this.$confirm("是否要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids == "") {
            return this.$message({
              type: "error",
              message: "请选择要设置的数据",
            });
          }

          const { data } = await changeStatus({
            ids: this.ids,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.tableList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    onClear(v) {
      if (v == 1) {
        this.list.receiveWay = null;
      } else if (v == 2) {
        this.list.status = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.tableList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.tableList();
    },
  },
};
</script>
<style lang="less" scoped>
.couponList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 300px;
    }
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .btn {
    margin-top: 50px;
    float: left;
  }
}
</style>