import request from '../utils/request'
import baseUrl from './baseUrl'

export const appListAPI = (data) => request({
    url: baseUrl + '/versionInfo/selectForBack',
    method: 'POST',
    data
})


export const addAppAPI = (data) => request({
    url: baseUrl + '/versionInfo/add',
    method: 'POST',
    data
})
export const appDetailAPI = (data) => request({
    url: baseUrl + '/versionInfo/selectForId',
    method: 'POST',
    data
})
export const delAppAPI = (data) => request({
    url: baseUrl + '/versionInfo/deleteForId',
    method: 'POST',
    data
})
export const addPush = (data) => request({//添加推送
    url: baseUrl + '/push/add',
    method: 'POST',
    data
})
export const pushList = (data) => request({//推送列表
    url: baseUrl + '/push/selectForBack',
    method: 'POST',
    data
})
export const delPush = (data) => request({//删除推送
    url: baseUrl + '/push/deleteById',
    method: 'POST',
    data
})
export const pushOut = (data) => request({//发送推送
    url: baseUrl + '/push/modify',
    method: 'POST',
    data
})
export const pushDetail = (data) => request({//推送详情
    url: baseUrl + '/push/selectForId',
    method: 'POST',
    data
})